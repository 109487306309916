<template>
  <div class="card shadow-sm">
    <form id="formItems" class="needs-validation" novalidate>
      <div class="card-header">
        <h3 class="card-title" style="font-size: 20px; font-weight: bold">
          {{ $t("editpricelist") }}
        </h3>
      </div>
      <!-- form -->
      <div class="card-body">
        <div class="row text-sm-start d-flex justify-content-center mb-4">
          <div class="col-sm-5">
            <label for="selectMainProduct" class="form-label required">
              รหัสpricelist</label
            >
            <input disabled v-model="form.code" class="form-control mb-4" />
          </div>

          <div class="col-sm-5">
            <label for="selectMainProduct" class="form-label required">
              ชื่อ pricelist</label
            >
            <input disabled v-model="form.name" class="form-control mb-4" />
          </div>
        </div>

        <div class="row">
          <label for="secondaryProductCode" class="form-label col-sm-10"
            >ค้นหารหัสสินค้า</label
          >
          <div class="col-sm-4">
            <input type="text" class="form-control" v-model="searchText" />
          </div>
          <div class="col-sm-2">
            <button
              type="button"
              class="btn btn-sm btn-light btn-success"
              style="font-size: 14px; font-weight: bold"
              @click="getOneItem()"
            >
              {{ $t("search") }}
            </button>
          </div>
          <div class="col-sm-6 text-end">
            <vue3-json-excel
              :json-data="dataItemsShow"
              :fields="json_fields"
              type="xls"
              :name="'Price_List_'+form.code+'.xls'"
              class="me-2"
            >
              <button
                class="btn btn-sm btn-light btn-success"
                style="font-size: 14px; font-weight: bold"
              >
                <span class="svg-icon svg-icon-2x dark">
                  <inline-svg src="media/Excel.svg" />
                  Download Excel
                </span>
              </button>
            </vue3-json-excel>

            <button
              type="button"
              class="btn btn-sm btn-light btn-success"
              style="font-size: 14px; font-weight: bold"
              @click="addItem()"
            >
              {{ $t("addon") }}
            </button>
            <EditItem
              :dialogEditItem="dialogEditItem"
              :form="form"
              @addProduct="addProduct"
              @closeDialogEditItem="closeDialogEditItem"
            />
          </div>
        </div>

        <div class="table-responsive" style="padding-top: 3px">
          <a-table :dataSource="dataItemsShow" :columns="columns">
            <div
              :slot-scope="{
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
                column,
              }"
              class="custom-filter-dropdown"
            >
              <!-- v-ant-ref="(c) => (searchInput = c)" -->
              <!-- :placeholder="`Search ${column.dataIndex}`"
              :value="selectedKeys[0]"
              @change="
                (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
              "
              @pressEnter="() => handleSearch(selectedKeys, confirm)"
              style="width: 188px; margin-bottom: 8px; display: block" -->
              <input id="searchInput" ref="searchInput" type="text" />
              <button
                type="button"
                @click="() => handleSearch(selectedKeys, confirm)"
                icon="search"
                size="small"
                style="width: 90px; margin-right: 8px"
              >
                Search
              </button>
              <button
                @click="() => handleReset(clearFilters)"
                size="small"
                style="width: 90px"
              >
                Reset
              </button>
            </div>
            <!-- <a-icon
              slot="filterIcon"
              slot-scope="filtered"
              type="search"
              :style="{ color: filtered ? '#108ee9' : undefined }"
            /> -->
            <i
              class="bi bi-three-dots fs-2"
              slot="filterIcon"
              slot-scope="filtered"
              type="search"
              :style="{ color: filtered ? '#108ee9' : undefined }"
            ></i>
            <template #headerCell="{ column }">
              <template v-if="column.dataIndex === 'no'">
                {{ $t("no") }}
              </template>
              <template v-if="column.dataIndex === 'productcode'">
                {{ $t("productcode") }}
              </template>
              <template v-if="column.dataIndex === 'Sale Price'">
                {{ $t("Sale Price") }}
                <span class="required"></span>
              </template>
              <template v-if="column.dataIndex === 'Spqty'">
                <span class="required">{{ $t("Spqty") }}</span>
              </template>
              <template v-if="column.dataIndex === 'U/M'">
                <span class="required">
                  {{ $t("U/M") }}
                </span>
              </template>
              <template v-if="column.dataIndex === 'productname'">
                {{ $t("productname") }}
              </template>
            </template>

            <template #bodyCell="{ column, record, index }">
              <template v-if="column.dataIndex === 'no'">
                {{ record.no }}
              </template>
              <template v-if="column.dataIndex === 'productcode'">
                <span>
                  {{ record.product ? record.product.code : record.code }}
                </span>
              </template>
              <template v-if="column.dataIndex === 'sale_price'">
                <input
                  type="number"
                  class="form-control"
                  v-model="record.sale_price"
                />
              </template>
              <template v-if="column.dataIndex === 'Spqty'">
                <input
                  type="number"
                  class="form-control"
                  v-model="record.spqty"
                />
              </template>
              <template v-if="column.dataIndex === 'U/M'">
                <!-- {{ formatUnit(record.u_m) || record.u_m }} -->
                <select
                  class="form-select mb-4"
                  v-model="record.productUnitId"
                  id="validationTooltip01"
                >
                  <option v-for="item in units" :key="item" :value="item.id">
                    {{ item.code }} |
                    {{ item.name }}
                  </option>
                </select>
              </template>
              <template v-if="column.dataIndex === 'productname'">
                {{ record.product ? record.product.name : record.name }}
              </template>
              <template v-if="column.dataIndex === 'action'">
                <div class="row">
                  <button
                    type="button"
                    class="btn btn-link btn-color-muted btn-active-color-danger m-0 p-0"
                    @click="confirmDelete(record, index)"
                  >
                    <i class="bi bi-trash3-fill"></i>
                  </button>
                </div>
              </template>
              <template v-if="column.dataIndex === 'actionsubmit'">
                <div class="row">
                  <button
                    type="button"
                    class="btn btn-sm btn-light btn-primary me-0"
                    style="font-size: 14px; font-weight: bold"
                    @click="submit(record)"
                  >
                    {{ $t("save") }}
                  </button>
                </div>
              </template>
            </template>
          </a-table>
          <!-- <table
            class="
              table table-hover table-rounded table-striped
              border
              gy-7
              gs-7
            "
          >
            <thead>
              <tr
                style="width: 100%"
                class="
                  fw-bold
                  fs-5
                  text-gray-800
                  border-bottom-2 border-gray-200
                  mw-200px
                "
              >
                <th>{{ $t("no") }}</th>
                <th>{{ $t("productcode") }}</th>
                <th>Sale Price</th>
                <th>Spqty</th>
                <th>U/M</th>
                <th>{{ $t("productname") }}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="dataItemsShow.length < 1">
                <td class="text-center" colspan="6">ไม่มีข้อมูล</td>
              </tr>
              <tr v-for="(item, index) in dataItemsShow" :key="index">
                <td>{{ index + 1 }}</td>
                <td>{{ item.product ? item.product.code : item.code }}</td>
                <td>
                  <input
                    type="number"
                    class="form-control"
                    v-model="item.sale_price"
                  />
                </td>
                <td>
                  <input
                    type="number"
                    class="form-control"
                    v-model="item.spqty"
                  />
                </td>
                <td>{{ item.u_m }}</td>
                <td>{{ item.product ? item.product.name : item.name }}</td>

                <td>
                  <div>
                    <button
                      type="button"
                      class="
                        btn btn-link btn-color-muted btn-active-color-danger
                        m-0
                        p-0
                      "
                      @click="confirmDelete(item, index)"
                    >
                      <i class="bi bi-trash3-fill"></i>
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table> -->
        </div>
      </div>

      <hr class="pt-0 mt-0" style="color: LightGrey" />

      <footer class="m-6 text-right">
        <div class="card-toolbar">
          <div class="ol-sm-12 text-right">
            <!-- <button
            type="button"
            class="btn btn-sm btn-light btn-primary me-2"
            style="font-size: 14px; font-weight: bold"
            @click="submit()"
          >
            {{ $t("save") }}
          </button> -->
            <button
              type="button"
              class="btn btn-sm btn-light"
              style="font-size: 14px; font-weight: bold"
              @click="cancel"
            >
              {{ $t("back") }}
            </button>
          </div>
        </div>
      </footer>
    </form>
  </div>
</template>

<script>
import EditItem from "./components/dialogEditItem.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import whApi from "@/api/warehouse/";
import transferApi from "@/api/transfer/";

export default {
  components: {
    EditItem,
  },
  setup() {
    document.title = "J&N | สร้างใบโอนสินค้า";
  },
  data() {
    // this.cacheData = data.map((item) => ({ ...item }));
    return {
      searchText: "",
      json_fields: {
        code: "product.code",
        name: "product.name",
        "Sale Price": "sale_price",
        Spqty: "spqty",
        "U/M": "productUnit.code",
      },
      columns: [
        // { title: "no", dataIndex: "no" },
        {
          title: "productcode",
          dataIndex: "productcode",
          // customFilterDropdown: true,
          // scopedSlots: {
          //   filterDropdown: "filterDropdown",
          //   filterIcon: "filterIcon",
          //   customRender: "name",
          // },
          // onFilter: (value, record) =>
          //   record.productcode.toLowerCase().includes(value.toLowerCase()),
          // onFilterDropdownVisibleChange: (visible) => {
          //   if (visible) {
          //     setTimeout(() => {

          //     }, 100);
          //   }
          // },
        },
        { title: "productname", dataIndex: "productname" },
        { title: "Sale Price", dataIndex: "sale_price", width: 150 },
        { title: "Spqty", dataIndex: "Spqty", width: 150 },
        { title: "U/M", dataIndex: "U/M" },
        { title: "action", dataIndex: "action" },
        { title: "actionsubmit", dataIndex: "actionsubmit" },
      ],
      searchProduct: "",
      isSubmit: false,
      isValidRetailPrice: false,
      dialogEditItem: false,
      form: {},
      ccsSelect: `background-size: 16px 12px;background-position: right 1rem center;background-repeat: no-repeat;background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%237E8299' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e")`,
      warehouses: [],
      dataItemsShow: [],
      dataItemsShowMaster: [],
      subProduct: [],
      isValid: false,
      units: [],

      searchInput: null,
    };
  },

  async created() {
    await this.getOne();
    await this.getAllUnit();
    await this.getOneItem();
  },
  methods: {
    async getAllUnit() {
      let companyId = parseInt(localStorage.getItem("companyId"));
      this.loading = true;
      let responseData = [];
      try {
        responseData = await whApi.unit.getAll(companyId);
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.units = responseData.data;
        this.loading = false;
      } else {
        this.loading = false;
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ดึงข้อมูลไม่สำเร็จ กรุณาตรวจสอบ SERVER",
        });
      }
    },
    async getOne() {
      this.loading = true;
      let getOneResponse = {};
      try {
        getOneResponse = await whApi.pricelist.getOne(
          this.$route.query.id,
          localStorage.getItem("companyId")
        );
      } catch (error) {
        console.log(error);
      }
      if (getOneResponse.response_status === "SUCCESS") {
        this.form = {
          code: getOneResponse.data.code,
          name: getOneResponse.data.name,
        };
        // this.form.code = getOneResponse.data.code;
        // this.form.name = getOneResponse.data.name;

        this.loading = false;
        // console.log("getOneResponse.data", getOneResponse.data);
      }
    },
    async getOneItem() {
      this.loading = true;
      let getOneResponse = {};
      try {
        const body = {
          priceListId: this.$route.query.id,
          search: this.searchText,
        };
        getOneResponse = await whApi.pricelist.searchItem(body);
      } catch (error) {
        console.log(error);
      }
      if (getOneResponse.response_status === "SUCCESS") {
        this.dataItemsShow = getOneResponse.data;
        this.dataItemsShowMaster = getOneResponse.data;
        this.dataItemsShow.forEach((element, index) => {
          element.no = index + 1;
        });
        this.loading = false;
        // console.log("getOneResponse.data", getOneResponse.data);
      }
    },
    addProduct(item) {
      // console.log("addProduct", item);
      this.dataItemsShow.push(item);
    },
    async getAllWarehouse() {
      let responseData = [];
      try {
        responseData = await whApi.warehouse.getAll();
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.warehouses = responseData.data;
      }
    },
    async submit(record) {
      // let isFound = this.dataItemsShowMaster.filter(
      //   (element) => record.productId == element.id
      // );
      await this.checkFormIsEmpty(record);
      if (this.isValid) {
        if (record.id) {
          Swal.fire({
            icon: "question",
            title: `${this.$t("want2submit")} ${this.$t("ornot")} ?`,
            confirmButtonText: `${this.$t("ok")} `,
            cancelButtonText: `${this.$t("cancel")} `,
            showCancelButton: true,
          }).then(async (result) => {
            if (result.isConfirmed) {
              // console.log("เข้าput");
              this.isSubmit = true;

              let responseData = [];
              let body = {
                priceListId: this.$route.query.id,
                productId: record.productId,
                sale_price: record.sale_price,
                spqty: record.spqty,
                productUnitId: record.productUnitId,
                companyId: parseInt(localStorage.getItem("companyId")),
              };

              try {
                responseData = await whApi.pricelist.updateItem(
                  record.id,
                  body
                );
              } catch (error) {
                console.log(error);
              }
              if (responseData.response_status === "SUCCESS") {
                Swal.fire({
                  icon: "success",
                  title: "แก้ไขข้อมูลสำเร็จ",
                  showConfirmButton: false,
                  timer: 2000,
                  timerProgressBar: true,
                }).then(() => {
                  this.getOneItem();
                });
              } else {
                Swal.fire({
                  icon: "error",
                  title: "แก้ไขข้อมูลไม่สำเร็จ",
                  text: responseData.message,
                  showConfirmButton: false,
                  timer: 2000,
                  timerProgressBar: true,
                }).then(() => {
                  this.getOneItem();
                });
              }
            }
          });
        } else {
          Swal.fire({
            icon: "question",
            title: `${this.$t("want2submit")} ${this.$t("ornot")} ?`,
            confirmButtonText: `${this.$t("ok")} `,
            cancelButtonText: `${this.$t("cancel")} `,
            showCancelButton: true,
          }).then(async (result) => {
            if (result.isConfirmed) {
              // console.log("เข้าpost");
              this.isSubmit = true;

              let responseData = [];
              let body = {
                priceListId: this.$route.query.id,
                productId: record.productId,
                sale_price: record.sale_price,
                spqty: record.spqty,
                productUnitId: record.productUnitId,
                companyId: parseInt(localStorage.getItem("companyId")),
              };

              try {
                responseData = await whApi.pricelist.postItem(body);
              } catch (error) {
                console.log(error);
              }
              if (responseData.response_status === "SUCCESS") {
                Swal.fire({
                  icon: "success",
                  title: "แก้ไขข้อมูลสำเร็จ",
                  showConfirmButton: false,
                  timer: 2000,
                  timerProgressBar: true,
                }).then(() => {
                  this.getOneItem();
                });
              } else {
                Swal.fire({
                  icon: "error",
                  title: "แก้ไขข้อมูลไม่สำเร็จ",
                  text: responseData.message,
                  showConfirmButton: false,
                  timer: 2000,
                  timerProgressBar: true,
                }).then(() => {
                  this.getOneItem();
                });
              }
            }
          });
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "กรุณากรอกข้อมูลให้ครบ",
          // text: responseData.message,
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
        });
      }
    },
    async submittransaction() {
      let responseData = [];
      let body = {
        transaction_number: "",
        form_warehouse_id: this.form.fromWarehouseId,
        to_warehouse_id: this.form.toWarehouseId,
        products: this.dataItemsShow,
        remark: this.form.remark,
      };
      try {
        responseData = await transferApi.transfer.create(body);
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        // this.productstock = responseData.data[0];
        Swal.fire({
          icon: "success",
          title: "บันทึกข้อมูลสำเร็จ",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
        }).then(() => {
          this.$router.go(-1);
        });
      }
    },
    validateForm() {
      let forms = document.querySelectorAll(".needs-validation");
      Array.prototype.slice.call(forms).forEach(function (form) {
        form.addEventListener(
          "submit",
          function (event) {
            event.preventDefault();
            if (!form.checkValidity()) {
              event.stopPropagation();
            }
            form.classList.add("was-validated");
          },
          false
        );
      });
    },
    checkFormIsEmpty(record) {
      !this.form.code
        ? (this.isValid = false)
        : !this.form.code
        ? (this.isValid = false)
        : !record.spqty
        ? (this.isValid = false)
        : !record.productUnitId
        ? (this.isValid = false)
        : !record.sale_price
        ? (this.isValid = false)
        : (this.isValid = true);
    },
    cancel() {
      this.$router.go(-1);
    },
    addItem() {
      this.dialogEditItem = true;
    },
    closeDialogEditItem() {
      this.dialogEditItem = false;
    },
    async confirmDelete(item, index) {
      if (item.id) {
        Swal.fire({
          icon: "question",
          title: `${this.$t("want2delete")} ${item.product.code} ${this.$t(
            "ornot"
          )} ?`,
          confirmButtonText: `${this.$t("ok")} `,
          cancelButtonText: `${this.$t("cancel")} `,
          showCancelButton: true,
        }).then(async (result) => {
          if (result.isConfirmed) {
            let deleteResponse = [];
            deleteResponse = await whApi.pricelist.deleteItem(item.id);
            if (deleteResponse.response_status === "SUCCESS") {
              Swal.fire({
                icon: "success",
                title: this.$t("deletesuccessfull"),
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
              }).then(() => {
                this.getOneItem();
              });
            }

            // this.dataItemsShow.splice(index, 1);
          }
        });
      } else {
        this.dataItemsShow.splice(index, 1);
      }
    },
    handleSearch(selectedKeys, confirm) {
      confirm();
      this.searchText = selectedKeys[0];
    },

    handleReset(clearFilters) {
      clearFilters();
      this.searchText = "";
    },
    handleChange(value, key, column) {
      const newData = [...this.data];
      const target = newData.filter((item) => key === item.key)[0];
      if (target) {
        target[column] = value;
        this.data = newData;
      }
    },
  },
};
</script>
<style  scoped>
.custom-filter-dropdown {
  padding: 8px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.highlight {
  background-color: rgb(255, 192, 105);
  padding: 0px;
}
</style>